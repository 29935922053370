<template>
    <div>
        <div class="table-page-title">
            <div>
                <el-form :model="searchForm" class="search-form" :inline="true">
                    <el-col>
                        <el-form-item label="" prop="departmentId">
                            <el-select class="small-input" @change="handleChangeDepartment" v-model="searchForm.departmentId"
                                       placeholder="请选择医院">
                                <el-option
                                        v-for="item in departmentList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" prop="building">
                            <el-select clearable class="small-input" v-model="searchForm.building" placeholder="请选择楼宇" @change="changeBuilding">
                                <el-option
                                        v-for="item in buildingList"
                                        :key="item.building"
                                        :label="item.building"
                                        :value="item.building">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" prop="floor">
                            <el-select clearable class="small-input" v-model="searchForm.floor" placeholder="请选择楼层" @change="changeFloor">
                                <el-option
                                        v-for="item in floorList"
                                        :key="item.floor"
                                        :label="item.floor"
                                        :value="item.floor">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" prop="place">
                            <el-select clearable class="small-input" v-model="searchForm.place" placeholder="请选择位置" @change="changePlace">
                                <el-option
                                        v-for="item in placeList"
                                        :key="item.place"
                                        :label="item.place"
                                        :value="item.place">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-form-item label="" prop="deviceType">
                        <el-select clearable class="small-input" v-model="searchForm.deviceType" placeholder="请选择设备类型">
                            <el-option
                                    v-for="item in deviceTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="createTime">
                        <el-date-picker class="small-input"
                                v-model="searchForm.createTime"
                                        type="datetime"
                                :value-format="this.formatDate"
                                placeholder="请选择下单时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="" prop="returnTime">
                        <el-date-picker class="small-input"
                                v-model="searchForm.returnTime"
                                        type="datetime"
                                :value-format="this.formatDate"
                                placeholder="请选择订单完成时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select clearable class="small-input" v-model="searchForm.refund" placeholder="是否退款">
                            <el-option
                                    v-for="item in refundList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="search-form-btn">
                <el-button size="small" icon="el-icon-search" type="primary" @click="search()">搜 索</el-button>
                <el-button size="small" icon="el-icon-refresh" type="primary" @click="reset()">重 置</el-button>
                <el-button size="small" icon="el-icon-document" type="primary" @click="exportOrder()">导出数据</el-button>
            </div>
        </div>
        <div class="table-container">
            <el-table
                    class="table"
                    height="100%"
                    v-loading="tableLoading"
                    :data="tableData">
                <el-table-column
                        label="订单号"
                        prop="orderNo"
                        fixed="left"
                        align="center"
                        header-align="center"
                        width="160"
                >
                    <template slot-scope="scope">
                        <span class="link_word" @click="goDetail(scope.row.orderNo)" style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;       white-space: normal;">{{ scope.row.orderNo || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="下单时间"
                        prop="createTime"
                        width="180"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="订单完成时间"
                        prop="endTime"
                        width="180"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="设备类型"
                        prop="deviceType"
                        width="80"
                        align="center"
                        header-align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.deviceType ===0?"陪护床":scope.row.deviceType ===1 ? "轮椅": '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="医院"
                        align="center"
                        header-align="center"
                        show-overflow-tooltip="true"
                        width="180"
                        prop="department">
                    <template slot-scope="scope">
                        <span class="link_word" @click="goUnitDetail(scope.row.departmentId)" style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;       white-space: normal;">{{ scope.row.department || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="楼层位置"
                        align="center"
                        width="200"
                        header-align="center"
                        prop="floor">
                    <template slot-scope="scope">
                        {{ scope.row.floor || '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="押金金额"
                        prop="depositAmount"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                    <template slot-scope="{row}">
                        <span v-if="row.depositAmount != null && row.depositAmount !=0">￥</span><span>{{row.depositAmount}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="是否退款"
                        prop="depositStatus"
                        align="center"
                        header-align="center"
                        width="120"
                        :formatter="commonFormatter"
                >
                    <template slot-scope="{row}">
                        {{ row.depositStatus === 1 ? '否' : row.depositStatus ===
                        2 ? '否' : row.depositStatus === 3 ? '是' : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="退款时间"
                        prop="returnTime"
                        width="180"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="用户名"
                        prop="userName"
                        align="center"
                        header-align="center"
                >
                    <template slot-scope="scope">
                        {{ scope.row.userName || '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="手机号"
                        prop="telephone"
                        width="120"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="top_10 right right_10"
                :current-page="pageNum"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import tableUse from "@/mixins/tableUse";
    import {setMenuList} from "@/assets/js/common";

    export default {
        name: "DepositStatistics",
        mixins: [tableUse],
        data() {
            return {
                searchForm: {
                    deviceType: '',
                    refund: '',
                    createTime: '',
                    returnTime: '',
                    departmentId: null,
                    building: '',
                    floor: '',
                    place: '',
                    positionId:'',
                },
                tableData: [],
                deviceTypes:[],
                refundList:[],
                departmentList:[],
                buildingList:[],
                floorList:[],
                placeList:[],
                positionList:[],
                formatDate:'yyyy-MM-dd HH:mm:ss'
            }
        },
        methods: {
            reset() {
                this.searchForm = {
                    deviceType: '',
                    refund: null,
                    createTime: '',
                    returnTime: '',
                    departmentId: null,
                    building: '',
                    floor: '',
                    place: '',
                    positionId:''
                }
                this.search();
            },
            goDetail(orderNo){
                setMenuList({
                    path:'/orderDetail',
                    name:'订单详情'
                })
                this.$router.push({name:'OrderDetail',query:{orderNo}})
            },
            goUnitDetail(departmentId) {
                setMenuList({
                    path: '/unitDetail',
                    name: '单位详情'
                })
                this.$router.push({name: 'UnitDetail',query:{id:departmentId}})
            },
            getTableData() {
                this.tableLoading = true;
                this.searchForm.pageNum = this.pageNum;
                this.searchForm.pageSize = this.pageSize;
                this.searchForm.type = 3;
                this.$axios.post("/sys-api/order-api/orderPage2", this.searchForm, (res) => {
                    this.tableLoading = false;
                    if (res.code === '100') {
                        this.total = res.data.total;
                        this.tableData = res.data.list;
                    }
                })
            },
            exportOrder: function () {
                this.tableLoading = true;
                this.searchForm.pageNum = null;
                this.searchForm.pageSize = null;
                this.searchForm.type = 3;
                this.$axios.post("/sys-api/order-api/exportOrder", this.searchForm, (res) => {
                    this.tableLoading = false;
                    if(!res){
                        this.$message.error("下载失败");
                        return;
                    }
                    const objectUrl = window.URL.createObjectURL(new Blob(['\uFEFF' + res]));
                    // window.URL.createObjectURL(new Blob(['\uFEFF' + content]))
                    const a = document.createElement('a');
                    a.href = objectUrl;
                    a.setAttribute('download', new Date().getTime() + '.csv');
                    a.click();
                })
            },
            getDepartmentList(){
                this.$axios.get("/sys-api/site-api/getDepartment",{},(res) => {
                    if(res.code === '100'){
                        this.departmentList = res.data;
                    }
                })
            },
            handleChangeDepartment(){
                this.searchForm.positionId = '';
                this.searchForm.building = "";
                this.searchForm.floor = "";
                this.searchForm.place = "";
                this.getPositionList();
            },
            getPositionList(){
                this.$axios.get("/sys-api/site-api/getPosition",{
                    departmentId:this.searchForm.departmentId
                },(res) => {
                    if(res.code === '100'){
                        this.getBuildingList(res.data || []);
                        //this.getFloorList(res.data || []);
                        //this.getPlaceList(res.data || []);
                        this.positionList = res.data || [];
                    }
                })
            },
            changeBuilding(){
                this.searchForm.floor = "";
                this.searchForm.place = "";
                this.getFloorList(this.positionList);
            },
            changeFloor(){
                this.searchForm.place = "";
                this.getPlaceList(this.positionList);
            },
            changePlace(){
            },
            getBuildingList(arr){
                this.buildingList = [];
                arr.forEach((item) => {
                    if(this.buildingList.every((exist) => {return item.building !== exist.building})){
                        this.buildingList.push(item);
                    }
                })
            },
            getFloorList(arr){
                this.floorList = [];
                if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
                arr.forEach((item) => {
                    if(this.floorList.every((exist) => {return item.floor !== exist.floor})){
                        this.floorList.push(item);
                    }
                })
            },
            getPlaceList(arr){
                this.placeList = [];
                if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
                if(this.searchForm.floor) arr = arr.filter((item) => {return item.floor === this.searchForm.floor});
                arr.forEach((item) => {
                    if(this.placeList.every((exist) => {return item.place !== exist.place})){
                        this.placeList.push(item);
                    }
                })
            },
        },
        mounted() {
            this.getTableData();
            // require引用时，放src和放statci都可以，建议放static
            const testJson = require('../../../public/common_cofig.json');
            const {refundList,deviceTypes} = testJson;
            this.refundList = refundList;
            this.deviceTypes = deviceTypes;
            this.getDepartmentList();
        }
    }
</script>

<style scoped lang="scss">
    .table-container {
        height: calc(100% - 155px);
    }
</style>
